.footer {
  width: 100%;
  padding: 1rem 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  color: rgba(41, 37, 36, 0.6);
}

.footer a {
  color: rgba(41, 37, 36, 0.6);
  text-decoration: underline;
}